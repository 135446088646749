<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-end">
        <div class="d-flex align-items-center mr-2">
          <b-badge
            variant="success"
            size="sm"
            class="mr-2 px-1"
            @click="handleFilter('has')"
          >
            <b-icon icon="check-circle" variant="success" />
          </b-badge>
          <span> Anexados</span>
        </div>
        <div class="d-flex align-items-center">
          <b-badge
            variant="danger"
            size="sm"
            class="px-1 mr-2"
            @click="handleFilter('empty')"
          >
            <b-icon icon="x-circle" variant="danger" />
          </b-badge>
          <span>Não Anexados</span>
        </div>
        <div class="d-flex align-items-center">
          <b-badge
            variant="warning"
            size="sm"
            class="px-1 mx-2"
            @click="handleFilter('empty')"
          >
            <b-icon icon="clock" variant="warning" />
          </b-badge>
          <span>Aguardando validação</span>
        </div>
      </div>
      <!-- <b-button
        class="btn-add with-content"
        @click="handleImportAutomaticCertificate()"
      >
        Importar automaticamente
      </b-button> -->
    </div>
    <cards-list grid="1" class="mr-0">
      <b-card
        v-for="(item, index) in items"
        :key="index"
        class="
          mb-3
          px-3
          py-1
          d-flex
          flex-md-row
          gap-4
          w-100
          justify-content-between
        "
        no-body
      >
        <strong class="d-flex align-items-start pt-3">
          <b-badge
            class="mr-2 p-1 mt-1"
            :class="setStatus(item).badge"
          >
            <b-icon :icon="setStatus(item).icon" />
          </b-badge>
          <div>
            <span>{{ item.name }} </span>
            <section v-if="item.updated_at">
              <small class="d-block">
                Certificado atualizada em:
                {{ item.updated_at | dateFull }}
              </small>
              <small v-if="setStatus(item).valid" class="d-block">
                Válido até:
                {{ item.valid_certificate | dateFull }}
              </small>
            </section>
            <small class="d-block" v-else> Não anexado </small>
          </div>
        </strong>

        <div
          class="
            d-flex
            align-items-center
            justify-content-end
            col-md-5
            gap-4 
          "
        >
          <b-button
            size="sm"
            class="d-flex align-items-center justify-content-end w-100"
            variant="light"
            @click="handleOpenReferenceCertificate(item)"
            target="_blank"
            v-if="item.url !== ''"
          >
            <b-icon-globe class="mr-2" />
            <span class="text-nowrap">Visitar sistema</span>
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="handleOpenCertificate(item)"
            target="_blank"
            v-if="item.certificate"
            class="w-100"
          >
            <b-icon icon="patch-check" class="mr-2" />
            <span class="text-nowrap">Certificado Anexado</span>
          </b-button>
          <b-button
            v-else
            size="sm"
            variant="info"
            @click="handleAddCertificate(item, index)"
            target="_blank"
            class="w-100"
          >
            <b-icon icon="paperclip" />
            <span class="text-nowrap">Anexar certificado</span>
          </b-button>
        </div>
      </b-card>
    </cards-list>
    <b-modal
      id="add-certificate-compliance"
      size="md"
      hide-footer
      centered
      :title="
        editCertificate
          ? `Editar certificado ${selectedCertificate.name}`
          : 'Adicionar certificado'
      "
    >
      <b-card
        no-body
        class="p-2"
        v-if="selectedCertificate && selectedCertificate.certificate"
      >
        <span class="d-block mb-2">
          certificado anexado em
          {{ selectedCertificate.created_at | dateFull }}
        </span>
        <a
          class="text-left d-flex align-items-center line-height-1"
          v-bind:href="selectedCertificate.certificate.file"
          target="_blank"
          download
        >
          <b-icon icon="paperclip" class="mr-2 m-0" />
          <span> {{ selectedCertificate.certificate.name }}</span>
        </a>
      </b-card>

      <file-upload @upload="handleUploadCertificate" />
    </b-modal>
    <b-modal
      id="automatic-certificate-compliance"
      size="md"
      :hide-footer="!erros_imports.length"
      hide-header
      body-class="py-4"
      centered
      no-close-on-esc
    >
      <div
        class="d-flex align-items-center flex-column"
        v-if="!erros_imports.length"
      >
        <blockquote>
          Fazendo a importação automática dos certificados
        </blockquote>
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
          class="my-5"
        />

        <b-progress max="100" height="9px" class="w-100">
          <b-progress-bar :value="automatic_attemp" />
          <progress-line class="loading" />
        </b-progress>
      </div>
      <div v-else>
        <strong class="d-block">
          Não conseguimos importar automaticamente os seguintes certificados
        </strong>
        <div class="my-2">
          <p v-for="error in erros_imports" :key="error">
            <b-icon icon="x-circle" variant="danger" /> {{ error }}
          </p>
        </div>
      </div>
      <template #modal-footer="{ close }">
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" @click="close()"> Fechar </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="import-certificate-example"
      size="md"
      :title="`Onde encontrar ${selectedCertificate.name}?`"
      hide-footer
      centered
      body-class="py-4"
    >
      <div>
        <p>
          disponível em:
        </p>
        <p style="word-break: break-word;">{{ selectedCertificate.url }}</p>
        <b-button
          variant="primary"
          block
          :href="selectedCertificate.url"
          target="_blank"
        >
          Acessar sistema de {{ selectedCertificate.name }}
        </b-button>

        <!-- <b-button
          variant="outline-primary"
          block
          class="h-auto"
          @click="handleOpenPreview()"
        >
          Ver exemplo do documento de {{ selectedCertificate.name }}
        </b-button> -->
      </div>
    </b-modal>
    <!-- <b-modal
      id="preview-certificate-example"
      size="md"
      :title="`Exemplo do documento ${selectedCertificate.name}?`"
      hide-footer
      centered
    >
      <div>
        <img
          width="100%"
          :src="selectedCertificate.example"
          :alt="selectedCertificate.name"
        />
      </div>
    </b-modal> -->
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import FileUpload from "@/components/FileUpload";
import ProgressLine from "@/components/Progress";
import { mapActions } from "vuex";

export default {
  mixins: [listPaginated],

  components: {
    FileUpload,
    ProgressLine,
  },
  data() {
    return {
      editCertificate: false,
      importing: false,
      per_page: 24,
      selectedCertificate: {
        example: "",
      },
      uploadedFiles: [],
      erros_imports: [],
      action_name: "certificates_files",
      params: {},
      automatic_attemp: 0,
      filter: "",
      selectedUser: {
        id: null,
      },
      provider: null,
      status: 1,
    };
  },
  methods: {
    ...mapActions(["edit_certificates_file", "import_certificate_compliance"]),
    setStatus(item){
      let currentDatetime = new Date();
      let icon
      let badge
      let valid = false
      if (item.certificate){
        if (item.valid_certificate < currentDatetime){
          icon = 'check-circle'
          badge = 'badge-success'
          valid = true
        } else {
          icon = 'clock'
          badge = 'badge-warning'
        }
      } else {
        icon = 'x-circle'
        badge = 'badge-danger'
      }
      return {icon, badge, valid}
    },
    calcAllInputedCertificates() {
      return this.items?.filter((item) => item.certificate).length;
    },
    handleOpenReferenceCertificate(item) {
      this.selectedCertificate = item;
      this.$bvModal.show("import-certificate-example");

      const name = this.selectedCertificate.name
        .replaceAll(" ", "-")
        .toLowerCase();

      try {
        this.selectedCertificate.example = require(`@/assets/images/certificates/${name}.png`);
      } catch (error) {
        this.selectedCertificate.example = require(`@/assets/images/certificates/certidão-de-regularidade-fgts.png`);
      }
    },

    // handleOpenPreview() {
    //   this.$bvModal.show("preview-certificate-example");
    // },

    handleUploadCertificate(file) {
      const id = file[0].id;
      this.selectedCertificate = {
        ...this.selectedCertificate,
        certificate: id,
      };
      this.handleEditCertificate(file.id);
    },
    handleFilter(filter) {
      this.getList();
      this.filter = filter;
      if (filter === "has") {
        this.items = this.items.filter((item) => item.certificate);
      }
      if (filter === "empty") {
        this.items = this.items.filter((item) => !item.certificate);
      }
    },
    handleEditCertificate() {
      this.edit_certificates_file({
        ...this.selectedCertificate,
        created_at: new Date().toISOString(),
      }).then(() => this.getList());
      this.$forceUpdate();
      this.$bvModal.hide("add-certificate-compliance");
    },
    handleOpenCertificate(certificate) {
      this.editCertificate = true;
      this.selectedCertificate = certificate;
      this.$bvModal.show("add-certificate-compliance");
    },
    handleAddCertificate(item) {
      this.selectedCertificate = item;
      this.$bvModal.show("add-certificate-compliance");
    },
    async handleImportAutomaticCertificate() {
      this.$bvModal.show("automatic-certificate-compliance");
      this.importing = true;
      this.erros_imports = [];

      for (const index in this.items) {
        const { id } = this.items[index];
        await this.import_certificate_compliance(id);
        this.automatic_attemp = (index + 1) * 30;
      }

      this.importing = false;
      this.getList();
      this.automatic_attemp = 0;
      this.erros_imports = this.items.flatMap(
        ({ certificate, name }) => !certificate && [name]
      );
    },
    handleImportManual() {
      this.$bvModal.show("add-certificate-compliance");
    },
    getDocuments(){
      this.params = {type: this.$route.params.document_id, 
      service: this.$route.params.service_id}
    }
  },
  created(){
    
    this.getDocuments()
  },
  watch: {
    $route() {
      this.getDocuments()
    },
  },
};
</script>